import {Link, useHistory, useParams} from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog, DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField
} from "@material-ui/core";
import {
  ArrowBack,
  ArrowBackIos,
  ArrowForwardIos,
  Check,
  Close,
  InsertDriveFile,
  NoteAdd,
  PictureAsPdf
} from "@material-ui/icons";
import React, {useState, useEffect, useCallback} from "react";
import {api} from "../../api";
import IconButton from "@material-ui/core/IconButton";
import {toast} from "react-toastify";
import TaskDocument from "./TaskDocument";
import Dropzone from "react-dropzone";
import {useSelector} from "react-redux";
import {userSelector} from "../../features/User/UserSlice";
import {useKeyPress} from "../../helpers/hooks";
import {Helmet} from "react-helmet";
import {scrollToTopSmooth} from "../../helpers/utils";


const TaskDetails = ({tasks, projectId, doneTasksFetched, toDoTasksFetched, projectForClient}) => {

  const {taskId} = useParams()
  const history = useHistory()
  const {isPm, hasPermissionToVendorPanel} = useSelector(userSelector)

  const [ taskStatus, setTaskStatus ] = useState(false)
  const [ taskHasChanged, setTaskHasChanged ] = useState(false)

  const [ imagePreview, setImagePreview ] = useState(false)
  const [ showImagePreview, setShowImagePreview ] = useState(false)
  const [ preAssemblyDocs, setPreAssemblyDocs ] = useState([])
  const [ postAssemblyDocs, setPostAssemblyDocs ] = useState([])

  const [ previewLoading, setPreviewLoading ] = useState(false)

  const [fileToDelete, setFileToDelete] = useState(null)
  const [confirmationDialog, setConfirmationDialog] = useState(false)

  const [capturing, setCapturing] = useState(false)

  const [taskNote, setTaskNote] = useState('')
  const task = tasks.find(task => task.id == taskId)

  useState(() => {
    scrollToTopSmooth()
  }, [window.location])

  const getFile = (file) => {
    return api.get(
      `/Documents/FileBase64/${file.id}`,
      {
        headers: {
          'X-TOKEN': localStorage.getItem('token')
        }
      }
    )
  }

  const updateTask = () => {
    return api.put(
      `/AssemblyTasks/Record/${taskId}`,
      {
        'task_status': taskStatus ? 'Zrealizowane' : 'Do realizacji',
        'technician_notes': taskNote
      },
      {
        headers: {
          'X-TOKEN': localStorage.getItem('token')
        }
      }
    ).then(() => toast.success('Zadanie zostało zaktualizowane'))
  }

  const handlePrevPreview = async () => {
    if (imagePreview.type === 'Dokumentacja przedmontażowa') {
      const currentDocIndex = preAssemblyDocs.findIndex(preAssemblyDoc => imagePreview?.id === preAssemblyDoc.id)
      if (currentDocIndex === 0) {
        return null
      } else {
        setPreviewLoading(true)
        const previousFile = preAssemblyDocs[currentDocIndex - 1]
        const newFileToPreview = await getFile(previousFile).then(res => {
          setPreviewLoading(false)
          return ({...previousFile, url: res?.data?.result.content, docType: res?.data?.result.type})
        })
        setImagePreview(newFileToPreview)
      }

    } else if (imagePreview.type === 'Dokumentacja pomontażowa') {
      const currentDocIndex = postAssemblyDocs.findIndex(preAssemblyDoc => imagePreview?.id === preAssemblyDoc.id)
      if (currentDocIndex === 0) {
        return null
      } else {
        setPreviewLoading(true)
        const previousFile = postAssemblyDocs[currentDocIndex - 1]
        const newFileToPreview = await getFile(previousFile).then(res => {
          setPreviewLoading(false)
          return ({...previousFile, url: res?.data?.result.content, docType: res?.data?.result.type})
        })
        setImagePreview(newFileToPreview)
      }
    }
  }

  const handleNextPreview = async () => {
    if (imagePreview.type === 'Dokumentacja przedmontażowa') {
      const currentDocIndex = preAssemblyDocs.findIndex(preAssemblyDoc => imagePreview?.id === preAssemblyDoc.id)
      if (currentDocIndex === preAssemblyDocs.length - 1) {
        return null
      } else {
        setPreviewLoading(true)
        const nextFile = preAssemblyDocs[currentDocIndex + 1]
        const newFileToPreview = await getFile(nextFile).then(res => {
          setPreviewLoading(false)
          return ({...nextFile, url: res?.data?.result.content, docType: res?.data?.result.type})
        })
        setImagePreview(newFileToPreview)
      }

    } else if (imagePreview.type === 'Dokumentacja pomontażowa') {
      const currentDocIndex = postAssemblyDocs.findIndex(preAssemblyDoc => imagePreview?.id === preAssemblyDoc.id)
      if (currentDocIndex === postAssemblyDocs.length - 1) {
        return null
      } else {
        setPreviewLoading(true)
        const nextFile = postAssemblyDocs[currentDocIndex + 1]
        const newFileToPreview = await getFile(nextFile).then(res => {
          setPreviewLoading(false)
          return ({...nextFile, url: res?.data?.result.content, docType: res?.data?.result.type})
        })
        setImagePreview(newFileToPreview)
      }
    }
  }

  const onDrop = async (acceptedFiles, action, prevFiles, docType) => {
    setCapturing(true)
    const newFiles = [...acceptedFiles]
    let responses = []

    for (let i = 0; i < newFiles.length; i++) {
      let formData = new FormData();

      formData.append("filename", newFiles[i])
      formData.append("projektdokument", projectId)
      formData.append("sourceRecord", taskId)
      formData.append("notes_title", newFiles[i].name)
      formData.append("typdokumentu", docType)
      formData.append('filelocationtype', 'I')
      formData.append('sourceModule', 'AssemblyTasks')
      formData.append('relationOperation', true)
      formData.append('relationId', 651)

      const uploaded = await api.post("/Documents/Record", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'multipart/form-data',
          'X-TOKEN': localStorage.getItem('token')
        },
      })

      const transformed = {
        data: newFiles[i],
        fileName: newFiles[i].name,
        type: newFiles[i].type,
        title: newFiles[i].name,
        id: uploaded?.data?.result?.id
      }

      responses.push(transformed)
    }
    action([...prevFiles, ...responses])
    setCapturing(false)
  }

  const deleteFile = (id) => {
    return api.request({
      url: `/Documents/Record/${id}`,
      method: 'DELETE',
      headers: {
        'X-TOKEN': localStorage.getItem('token')
      }
    })
  }

  useEffect(async() => {
    if (task?.documents.length > 0) {
      const preAssembly = task.documents.filter(doc => doc.type === 'Dokumentacja przedmontażowa')
      const postAssembly = task.documents.filter(doc => doc.type === 'Dokumentacja pomontażowa')

      setPreAssemblyDocs(preAssembly)
      setPostAssemblyDocs(postAssembly)
    }
  }, [task])

  useEffect(() => {
    if (task?.status) {
      setTaskStatus(task.status === 'Zrealizowane')
    }

    if (task?.note) {
      setTaskNote(task.note)
    }
  },[task])

  const arrowRightPress = useKeyPress('ArrowRight')
  const arrowLeftPress = useKeyPress('ArrowLeft')

  useEffect(() => {
    if (arrowRightPress) {
      handleNextPreview()
    }
    if (arrowLeftPress) {
      handlePrevPreview()
    }
  },[arrowRightPress, arrowLeftPress])

  if (!doneTasksFetched || !toDoTasksFetched) {
    return null
  }

  if (!task) {
    return null
  }

  return (
    <Box textAlign='left' p={2}>
      <Helmet defer={false}>
        <title>VPT - {task.name} - {task.orderName}</title>
      </Helmet>
      <Box mb={2}>
        <Link style={{textDecoration: 'none'}} to={isPm
          ? (`/tasks/${projectId}`)
          : hasPermissionToVendorPanel
            ? (`/supplier-tasks/${projectId}`)
            : (`/project/${projectId}`)}>
          <Button
            startIcon={<ArrowBack />}
            color='secondary'
            style={{height: '24px'}}
          >
            Wróc do listy zadań
          </Button>
        </Link>
      </Box>
      <Box mb={2}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Grid container>
              {isPm && <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Kontrahent:</b></p>
              </Grid>}
              {isPm && <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>{task.contractor || '-'}</p>
              </Grid>}

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Zlecenie:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {task.orderName || '-'}
                </p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Termin realizacji:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {task.implementationDate || '-'}
                </p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Kontakt:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {
                    (!task.contact.name && !task.contact.phone)
                      ? "-"
                      : task.contact ? <span>
                        {task.contact.name}{task.contact.name && ', '}
                        <a href={`mailto: ${task.contact.email}`}>{task.contact.email}</a>&nbsp;
                        <a href={`tel: ${task.contact.phone}`}>{task.contact.phone}</a>
                      </span>
                      : '-'
                  }
                </p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Opis:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {task.description || '-'}
                </p>
                {task.location.geotags.map(
                  (geotag, index) => {
                    return (
                      <p key={index} style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px', position: 'relative'}}>
                        <a href={geotag.url} target='_blank'>
                          {geotag.name}
                        </a>
                      </p>
                    )
                  }
                )}
              </Grid>

            </Grid>
          </Grid>


          <Grid item xs={12} lg={6}>
            <Grid container>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Numer projektu:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {task.projectNumber || '-'}
                </p>
              </Grid>

              {(isPm || hasPermissionToVendorPanel) && <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Technik:</b></p>
              </Grid>}
              {(isPm || hasPermissionToVendorPanel) && <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {task.supplier || '-'}
                </p>
              </Grid>}

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Lokalizacja:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px', position: 'relative'}}>
                  {
                    task.location
                      ? <a href={`https://www.google.com/maps/place/${task.location.street ? task.location.street : ''}+${task.location.street && task.location.buildingNumber ? task.location.buildingNumber : ''},+${task.location.city ? task.location.city : ''}`} target='_blank'>
                        {task.location.city} {task.location.street ? `${task.location.street} ${task.location.buildingNumber ? task.location.buildingNumber : ''}` : ''}
                      </a>
                      : '-'
                  }
                </p>
              </Grid>

              {!(isPm || hasPermissionToVendorPanel) && <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Project Manager:</b>
                </p>
              </Grid>}
              {!(isPm || hasPermissionToVendorPanel) && <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {
                    projectForClient.projectManager ? <span>
                          {projectForClient.projectManager.name},&nbsp;
                        <a
                          href={`mailto: ${projectForClient.projectManager.email}`}>{projectForClient.projectManager.email}</a>&nbsp;
                        <a
                          href={`tel: ${projectForClient.projectManager.phone}`}>{projectForClient.projectManager.phone}</a>
                        </span>
                      : '-'
                  }
                </p>
              </Grid>}

              {!(isPm || hasPermissionToVendorPanel) && <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Account Manager:</b>
                </p>
              </Grid>}
              {!(isPm || hasPermissionToVendorPanel) && <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  {
                    projectForClient.accountManager ? <span>
                          {projectForClient.accountManager.name},&nbsp;
                        <a
                          href={`mailto: ${projectForClient.accountManager.email}`}>{projectForClient.accountManager.email}</a>&nbsp;
                        <a
                          href={`tel: ${projectForClient.accountManager.phone}`}>{projectForClient.accountManager.phone}</a>
                        </span>
                      : '-'
                  }
                </p>
              </Grid>}

              <Grid item xs={3} xl={2} alignItems='center'>
                <p style={{
                  textAlign: 'right',
                  marginTop: '8px',
                  marginBottom: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}><b>Zrealizowane:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '4px', marginBottom: '8px'}}>
                  <Checkbox
                    style={{padding: 0}}
                    color='primary'
                    disabled={!(isPm || hasPermissionToVendorPanel)}
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    checked={taskStatus}
                    onChange={(ev) => {
                      setTaskStatus(ev.target.checked)
                      setTaskHasChanged(true)
                    }}
                  />
                </p>
              </Grid>

              <Grid item xs={3} xl={2}>
                <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px', color: taskNote ? 'red' : 'inherit'}}><b>Notatka:</b></p>
              </Grid>
              <Grid item xs={9} xl={10}>
                <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    multiline
                    rows={2}
                    rowsMax={10}
                    // disabled={!(isPm || hasPermissionToVendorPanel)}
                    value={taskNote}
                    onChange={(ev) => {
                      setTaskNote(ev.target.value)
                      setTaskHasChanged(true)
                    }}
                  />
                </p>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box display='flex' justifyContent='flex-end' mt={1}>
              <Button
                color='primary'
                variant='contained'
                onClick={updateTask}
                style={{height: '24px'}}
              >
                Zaktualizuj zadanie
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Grid container style={{borderBottom: '1px solid rgba(0,0,0,0.12)'}}>
        <Grid item xs={6}>
          <Box boxSizing='border-box' p={3} mb={5}>
            <h3 style={{marginTop: 0}}>Dokumentacja przedmontażowa:</h3>
            {<Grid container spacing={2} alignItems='center'>
              {preAssemblyDocs.map((doc, index) => {
                return <TaskDocument
                  key={doc.id}
                  doc={doc}
                  setImagePreview={setImagePreview}
                  setShowImagePreview={setShowImagePreview}
                  setConfirmationDialog={setConfirmationDialog}
                  setFileToDelete={setFileToDelete}
                />
              })}
              {(isPm || hasPermissionToVendorPanel) && <Grid item xs={4}>
                <Dropzone
                  onDrop={acceptedFiles => onDrop(acceptedFiles, setPreAssemblyDocs, preAssemblyDocs, 'Dokumentacja przedmontażowa')}>
                  {({getRootProps, getInputProps}) => (
                    <div style={{border: '1px dashed grey', borderRadius: '8px'}} {...getRootProps()}>
                      <Box display='flex' justifyContent='center'>
                        <IconButton color='primary' component='label'>
                          {/*<input*/}
                          {/*  type='file'*/}
                          {/*  accept='image/jpg, image/png, image/jpeg, application/pdf, application/docx, application/xlsx'*/}
                          {/*  hidden*/}
                          {/*  multiple*/}
                          {/*  onChange={ev => handleCapture(ev, setPreAssemblyDocs, preAssemblyDocs, 'Dokumentacja przedmontażowa')}*/}
                          {/*/>*/}
                          <NoteAdd style={{fontSize: 100}}/>
                        </IconButton>
                      </Box>
                      <Box display='flex' justifyContent='center'>
                        <Button
                          variant='contained'
                          color='primary'
                          component='label'
                          style={{height: '24px'}}
                        >
                          Dodaj pliki
                        </Button>
                      </Box>
                      <input {...getInputProps()} />
                      <p style={{fontWeight: 600, textAlign: 'center'}}>lub upuść tutaj</p>
                    </div>
                  )}
                </Dropzone>
              </Grid>}
            </Grid>}
          </Box>
        </Grid>
        <Grid item xs={6} style={{borderLeft: '1px solid rgba(0,0,0,0.12)', background: '#d7d7d7'}}>
          <Box boxSizing='border-box' p={3} mb={5}>
            <h3 style={{marginTop: 0}}>Dokumentacja pomontażowa:</h3>
            {<Grid container spacing={2} alignItems='center'>
              {postAssemblyDocs.map((doc, index) => {
                return <TaskDocument
                  key={doc.id}
                  doc={doc}
                  setImagePreview={setImagePreview}
                  setShowImagePreview={setShowImagePreview}
                  setConfirmationDialog={setConfirmationDialog}
                  setFileToDelete={setFileToDelete}
                />
              })}
              {(isPm || hasPermissionToVendorPanel) && <Grid item xs={4}>
                <Dropzone
                  onDrop={acceptedFiles => onDrop(acceptedFiles, setPostAssemblyDocs, postAssemblyDocs, 'Dokumentacja pomontażowa')}>
                  {({getRootProps, getInputProps}) => (
                    <div style={{border: '1px dashed grey', borderRadius: '8px'}} {...getRootProps()}>
                      <Box display='flex' justifyContent='center'>
                        <IconButton color='primary' component='label'>
                          {/*<input*/}
                          {/*  type='file'*/}
                          {/*  accept='image/jpg, image/png, image/jpeg, application/pdf, application/docx, application/xlsx'*/}
                          {/*  hidden*/}
                          {/*  multiple*/}
                          {/*  onChange={ev => handleCapture(ev, setPreAssemblyDocs, preAssemblyDocs, 'Dokumentacja przedmontażowa')}*/}
                          {/*/>*/}
                          <NoteAdd style={{fontSize: 100}}/>
                        </IconButton>
                      </Box>
                      <Box display='flex' justifyContent='center'>
                        <Button
                          variant='contained'
                          color='primary'
                          component='label'
                          style={{height: '24px'}}
                        >
                          Dodaj pliki
                        </Button>
                      </Box>
                      <input {...getInputProps()} />
                      <p style={{fontWeight: 600, textAlign: 'center'}}>lub upuść tutaj</p>
                    </div>
                  )}
                </Dropzone>
              </Grid>}
            </Grid>}
          </Box>
        </Grid>
      </Grid>
      <Dialog maxWidth='xl' open={capturing}>
        <DialogContent>
          <h3>Trwa dodawanie plików...</h3>
          <Box display='flex' justifyContent='center' m={4}>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog fullScreen open={showImagePreview} onClose={() => {
        setShowImagePreview(false)
      }}>
        <Box display='flex' alignItems='center' justifyContent='flex-end' mt={1}>
          <Button color='primary' variant='outlined' style={{height: '30px', marginRight: '16px'}} onClick={() => {
            var a = document.createElement("a"); //Create <a>
            a.href = imagePreview.url; //Image Base64 Goes here
            a.download = imagePreview.fileName; //File name Here
            a.click(); //Downloaded file
          }}>
            Pobierz
          </Button>
          <IconButton size='large' onClick={() => {
            setShowImagePreview(false)
          }}>
            <Close />
          </IconButton>
        </Box>
        <h3 style={{textAlign: 'center', margin: '8px'}}>
          {task?.location?.route && `(${task?.location.route})`} {task?.location.city}, {task?.location.street} {task?.location.buildingNumber}
        </h3>
        {isPm && task?.supplier && <h3
          style={{textAlign: 'center', color: 'black', margin: '1px'}}><span style={{fontWeight: 400}}>Technik:</span> {task?.supplier}</h3>}
        {task?.note && <h4
          style={{textAlign: 'center', margin: '1px', color: task?.note ? 'red' : 'inherit'}}>{task?.note}</h4>}
        <DialogContent>
          {imagePreview &&
            <div style={{width: '100%', height: '80vh', position: 'relative', display: 'flex', justifyContent: 'space-beteween', alignItems: 'center'}}>
              <Box style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <IconButton
                  size='large'
                  disabled={previewLoading}
                  onClick={() => handlePrevPreview()}
                >
                  <ArrowBackIos />
                </IconButton>
              </Box>
              {
                imagePreview.docType.includes('image')
                  ? <a style={{maxHeight: '100%', display: 'flex', justifyContent: 'center', width: '100%'}} onClick={e => e.preventDefault()} href={imagePreview?.url} download={imagePreview?.fileName || imagePreview?.title}>
                    <img style={{maxWidth: '100%', objectFit: 'contain'}} alt='preview' src={imagePreview?.url}/>
                  </a>
                  : imagePreview.docType.includes('pdf')
                    ? <Grid item xs={12}>
                      <Box height='100%' display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                        <Box display='flex' justifyContent='center'>
                          <IconButton color='primary' onClick={() => {
                            var a = document.createElement("a"); //Create <a>
                            a.href = imagePreview.url; //Image Base64 Goes here
                            a.download = imagePreview.fileName; //File name Here
                            a.click(); //Downloaded file
                          }}>
                            <PictureAsPdf style={{fontSize: 80}} />
                          </IconButton>
                        </Box>
                        <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all', cursor: 'pointer'}} onClick={() => {
                          var a = document.createElement("a"); //Create <a>
                          a.href = imagePreview.url; //Image Base64 Goes here
                          a.download = imagePreview.fileName; //File name Here
                          a.click(); //Downloaded file
                        }}>
                          {imagePreview.fileName}
                        </p>
                      </Box>
                      {(isPm || hasPermissionToVendorPanel) && <Box display='flex' justifyContent='center'>
                        <Button
                          onClick={() => {
                            setFileToDelete(document)
                            setConfirmationDialog(true)
                          }}
                        >
                          usuń
                        </Button>
                      </Box>}
                    </Grid>
                    : <Grid item xs={12}>
                      <Box height='100%' display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
                        <Box display='flex' justifyContent='center'>
                          <IconButton color='primary' onClick={() => {
                            var a = document.createElement("a"); //Create <a>
                            a.href = imagePreview.url; //Image Base64 Goes here
                            a.download = imagePreview.fileName; //File name Here
                            a.click(); //Downloaded file
                          }}>
                            <InsertDriveFile style={{fontSize: 80}} />
                          </IconButton>
                        </Box>
                        <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all', cursor: 'pointer'}} onClick={() => {
                          var a = document.createElement("a"); //Create <a>
                          a.href = imagePreview.url; //Image Base64 Goes here
                          a.download = imagePreview.fileName; //File name Here
                          a.click(); //Downloaded file
                        }}>
                          {imagePreview.fileName}
                        </p>
                      </Box>
                      {(isPm || hasPermissionToVendorPanel) && <Box display='flex' justifyContent='center'>
                        <Button
                          onClick={() => {
                            setFileToDelete(document)
                            setConfirmationDialog(true)
                          }}
                        >
                          usuń
                        </Button>
                      </Box>}
                    </Grid>

              }
              <Box style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'}}
              >
                <IconButton
                  size='large'
                  disabled={previewLoading}
                  onClick={() => handleNextPreview()}
                >
                  <ArrowForwardIos/>
                </IconButton>
              </Box>
            </div>
          }
        </DialogContent>
      </Dialog>

      <Dialog maxWidth='lg' open={confirmationDialog} onClose={() => setConfirmationDialog(false)}>
        <DialogContent>
          Czy na pewno chcesz usunąć dokument <b>{fileToDelete?.fileName}</b>?
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => {
            setConfirmationDialog(false)
          }} color="primary" startIcon={<Close />}>
            Nie
          </Button>
          <Button variant='contained' onClick={() => {
            setConfirmationDialog(false)
            deleteFile(fileToDelete?.id).then(() => {
              const filteredPre = preAssemblyDocs.filter(doc => doc.id !== fileToDelete?.id)
              const filteredPost = postAssemblyDocs.filter(doc => doc.id !== fileToDelete?.id)
              setPreAssemblyDocs(filteredPre)
              setPostAssemblyDocs(filteredPost)
              toast.warning('Dokument został usunięty')
            })
          }} color="primary" autoFocus startIcon={<Check />}>
            Tak
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default TaskDetails
