import {Link, Route, Switch, useHistory, useParams} from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  LinearProgress,
  Tab
} from "@material-ui/core";
import { saveAs } from 'file-saver'
import {
  ArrowBack,
  ArrowBackIos,
  ArrowForwardIos,
  Close,
  InsertDriveFile,
  PictureAsPdf,
  Search
} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getProject, getTasks, getTasksPLM} from "./ProjectsSlice";
import TaskTile from "../../common/Components/TaskTile";
import {api} from "../../api";
import {toast} from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import TaskDocument from "../../common/Components/TaskDocument";
import PLMSection from "./PLMSection";
import {TabPanel, TabList, TabContext} from "@material-ui/lab";
import {setActiveTab, userSelector} from "../User/UserSlice";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Pagination from "@material-ui/lab/Pagination";
import TaskDetails from "../../common/Components/TaskDetails";
import {debounce} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {scrollToTopSmooth} from "../../helpers/utils";

const ClientProjectDetails = () => {

  const { activeTab } = useSelector(userSelector)

  const [toDoTasks, setToDoTasks] = useState([])
  const [toDoTasksPage, setToDoTasksPage] = useState(1)
  const [toDoTasksLastPage, setToDoTasksLastPage] = useState(1)
  const [doneTasks, setDoneTasks] = useState([])
  const [doneTasksPage, setDoneTasksPage] = useState(1)
  const [doneTasksLastPage, setDoneTasksLastPage] = useState(1)
  const [toDoTasksFetched, setToDoTasksFetched] = useState(false)
  const [doneTasksFetched, setDoneTasksFetched] = useState(false)


  const [projectForClient, setProjectForClient] = useState(null)
  const [generatingReport, setGeneratingReport] = useState(false)
  const [tasks, setTasks] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()
  const { projectId } = useParams()
  const [ preAssemblyDocs, setPreAssemblyDocs ] = useState([])
  const [ postAssemblyDocs, setPostAssemblyDocs ] = useState([])
  const [ documentsLoaded, setDocumentsLoaded ] = useState(true)
  const [ imagePreview, setImagePreview ] = useState(false)
  const [ showImagePreview, setShowImagePreview ] = useState(false)
  const [ previewLoading, setPreviewLoading ] = useState(false)
  const [value, setValue] = React.useState(localStorage.getItem('activeTab') || activeTab);
  const [downloading, setDownloading] = useState(false)
  const [ currentPage, setCurrentPage ] = useState(1)
  const [locationSearchValue, setLocationSearchValue] = useState('')

  useEffect(() => {
    dispatch(getProject(projectId)).then(res => setProjectForClient(res.payload))
  }, [])

  useEffect(() => {
    dispatch(getTasksPLM({projectId, page: currentPage, location: locationSearchValue})).then(res => setTasks([...tasks, ...res.payload.tasks]))
  }, [history.location, currentPage])

  useEffect(() => {
    if (value === '2') {
      dispatch(getTasksPLM({projectId, page: 1, location: locationSearchValue})).then(res => setTasks([...res.payload.tasks]))
    }
  }, [value])

  useEffect(() => {
    dispatch(setActiveTab(value))
    localStorage.setItem('activeTab', value)
  }, [value])

  useState(() => {
    scrollToTopSmooth()
  }, [window.location])

  // useEffect(() => {
  //   if (projectForClient?.documents?.length > 0) {
  //     if (projectForClient?.documents.length > 0) {
  //       const preAssembly = projectForClient.documents.filter(doc => doc.type === 'Dokumentacja przedmontażowa')
  //       const postAssembly = projectForClient.documents.filter(doc => doc.type === 'Dokumentacja pomontażowa')
  //
  //       setPreAssemblyDocs(preAssembly)
  //       setPostAssemblyDocs(postAssembly)
  //     }
  //   }
  // }, [projectForClient])

  useEffect(() => {
    getToDoTasks()
  }, [toDoTasksPage, history.location])

  useEffect(() => {
    getDoneTasks()
  }, [doneTasksPage, history.location])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      updateTasks()
      getTasksPlm()
    }, 1000)
    return () => clearTimeout(timeoutId);
  }, [locationSearchValue]);


  const getToDoTasks = () => {
    dispatch(getTasks({projectId, status: 'Do realizacji', page: toDoTasksPage, location: locationSearchValue})).then(res => {
      setToDoTasks(res.payload.tasks)
      setToDoTasksLastPage(res.payload.lastPage)
      setToDoTasksFetched(true)
    })
  }

  const getDoneTasks = () => {
    dispatch(getTasks({projectId, status: 'Zrealizowane', page: doneTasksPage, location: locationSearchValue})).then(res => {
      setDoneTasks(res.payload.tasks)
      setDoneTasksLastPage(res.payload.lastPage)
      setDoneTasksFetched(true)
    })
  }

  const getTasksPlm = () => {
    dispatch(getTasksPLM({projectId, page: currentPage, location: locationSearchValue})).then(res => setTasks([...res.payload.tasks]))
  }

  const updateTasks = () => {
    getDoneTasks()
    getToDoTasks()
  }

  const filterTasks = () => {
    dispatch(getTasksPLM({projectId, page: 1, perPage: 100, location: locationSearchValue})).then(res => {
      setTasks(res.payload.tasks)
    })
  }

  const getDocumentation = () => {
    return api.get(
      `/SSingleOrders/AssemblyTasksZip/${projectId}`,
      {
        responseType: 'arraybuffer',
        headers: {
          'X-TOKEN': localStorage.getItem('token')
        }
      }
    )
  }

  const handlePrevPreview = async () => {
    if (imagePreview.type === 'Dokumentacja przedmontażowa') {
      const currentDocIndex = preAssemblyDocs.findIndex(preAssemblyDoc => imagePreview?.id === preAssemblyDoc.id)
      if (currentDocIndex === 0) {
        return null
      } else {
        setPreviewLoading(true)
        const previousFile = preAssemblyDocs[currentDocIndex - 1]
        const newFileToPreview = await getFile(previousFile).then(res => {
          setPreviewLoading(false)
          return ({...previousFile, url: res?.data?.result.content, docType: res?.data?.result.type})
        })
        setImagePreview(newFileToPreview)
      }

    } else if (imagePreview.type === 'Dokumentacja pomontażowa') {
      const currentDocIndex = postAssemblyDocs.findIndex(preAssemblyDoc => imagePreview?.id === preAssemblyDoc.id)
      if (currentDocIndex === 0) {
        return null
      } else {
        setPreviewLoading(true)
        const previousFile = postAssemblyDocs[currentDocIndex - 1]
        const newFileToPreview = await getFile(previousFile).then(res => {
          setPreviewLoading(false)
          return ({...previousFile, url: res?.data?.result.content, docType: res?.data?.result.type})
        })
        setImagePreview(newFileToPreview)
      }
    }
  }

  const handleNextPreview = async () => {
    if (imagePreview.type === 'Dokumentacja przedmontażowa') {
      const currentDocIndex = preAssemblyDocs.findIndex(preAssemblyDoc => imagePreview?.id === preAssemblyDoc.id)
      if (currentDocIndex === preAssemblyDocs.length - 1) {
        return null
      } else {
        setPreviewLoading(true)
        const nextFile = preAssemblyDocs[currentDocIndex + 1]
        const newFileToPreview = await getFile(nextFile).then(res => {
          setPreviewLoading(false)
          return ({...nextFile, url: res?.data?.result.content, docType: res?.data?.result.type})
        })
        setImagePreview(newFileToPreview)
      }

    } else if (imagePreview.type === 'Dokumentacja pomontażowa') {
      const currentDocIndex = postAssemblyDocs.findIndex(preAssemblyDoc => imagePreview?.id === preAssemblyDoc.id)
      if (currentDocIndex === postAssemblyDocs.length - 1) {
        return null
      } else {
        setPreviewLoading(true)
        const nextFile = postAssemblyDocs[currentDocIndex + 1]
        const newFileToPreview = await getFile(nextFile).then(res => {
          setPreviewLoading(false)
          return ({...nextFile, url: res?.data?.result.content, docType: res?.data?.result.type})
        })
        setImagePreview(newFileToPreview)
      }
    }
  }

  const getFile = (file) => {
    return api.get(
      `/Documents/FileBase64/${file.id}`,
      {
        headers: {
          'X-TOKEN': localStorage.getItem('token')
        }
      }
    )
  }

  const getReport = () => {
    setGeneratingReport(true)
    return api.get(
      `/SSingleOrders/Pdf/${projectId}`,
      {
        headers: {
          'X-TOKEN': localStorage.getItem('token'),

        },
        params: {
          templates: [38]
        }
      }
    ).then(res => {

      const pdf = res.data.result.data
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement("a");
      const fileName = res.data.result.name;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      setGeneratingReport(false)


    }, error => {
      toast.error('Nie udało się wygenerować raportu.')
      setGeneratingReport(false)
    })
  }

  if (!projectForClient) {
    return <Box m={10}>
      <CircularProgress />
    </Box>
  }

  return (
    <>
      <Helmet defer={false}>
        <title>VPT - {projectForClient.name} - {projectForClient.number}</title>
      </Helmet>
      <Switch>
      <Route exact path={`/project/${projectId}`} render={() => {
        return <Box textAlign='left' p={2}>
          <TabContext value={value}>
            <Box mb={2} display='flex' justifyContent='space-between'>
              <Link style={{textDecoration: 'none'}} to={'/'}>
                <Button
                  startIcon={<ArrowBack/>}
                  color='secondary'
                  style={{height: '24px'}}
                >
                  Wróc do listy projektów
                </Button>
              </Link>
            </Box>
            <Box mb={2} display='flex' justifyContent='center'>
              <Box pr={2}>
                <Button
                  fullWidth
                  variant='outlined'
                  // disabled={task?.documents.length === 0}
                  color='primary'
                  onClick={() => {
                    setDownloading(true)
                    getDocumentation().then(res => {
                      const blob = new Blob([res.data], {type: "octet/stream"});
                      const fileName = `VPT Dokumentacja ${projectForClient.number}.zip`;
                      saveAs(blob, fileName);
                      setDownloading(false)
                    })
                  }}
                >
                  Pobierz dokumentację
                </Button>
              </Box>

              <Box pr={2}>
                <Button disabled={generatingReport}
                        startIcon={generatingReport && <CircularProgress style={{height: '20px', width: '20px'}}/>}
                        onClick={getReport} color='primary' variant='outlined'>
                  Generuj raport pdf
                </Button>
              </Box>
              <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <ButtonGroup style={{height: '46px'}} size="large" aria-label="large button group">
                  <Button
                    color='primary'
                    variant={value === "1" ? "contained" : "outlined"}
                    onClick={() => setValue("1")}
                  >
                    Tryb PoS
                  </Button>
                  <Button
                    color='primary'
                    variant={value === "2" ? "contained" : "outlined"}
                    onClick={() => setValue("2")}
                  >
                    Tryb PLM
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>

            <Box>
              <Box mb={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} lg={6}>
                        <Grid container>
                          <Grid item xs={3} xl={2}>
                            <h3 style={{textAlign: 'right'}}>Numer projektu:</h3>
                          </Grid>
                          <Grid item xs={9} xl={10}>
                            <h3 style={{marginLeft: '16px'}}>{projectForClient.number || '-'}</h3>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid container>
                      <Grid item xs={3} xl={2}>
                        <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Projekt:</b></p>
                      </Grid>
                      <Grid item xs={9} xl={10}>
                        <p style={{
                          marginLeft: '16px',
                          marginTop: '8px',
                          marginBottom: '8px'
                        }}>{projectForClient.name || '-'}</p>
                      </Grid>

                      <Grid item xs={3} xl={2}>
                        <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>PO:</b></p>
                      </Grid>
                      <Grid item xs={9} xl={10}>
                        <p style={{
                          marginLeft: '16px',
                          marginTop: '8px',
                          marginBottom: '8px'
                        }}>{projectForClient.clientProjectNumber || '-'}</p>
                      </Grid>

                      <Grid item xs={3} xl={2}>
                        <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Termin umowy:</b></p>
                      </Grid>
                      <Grid item xs={9} xl={10}>
                        <p style={{
                          marginLeft: '16px',
                          marginTop: '8px',
                          marginBottom: '8px'
                        }}>{projectForClient.deadline || '-'}</p>
                      </Grid>

                      <Grid item xs={3} xl={2}>
                        <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Termin wykonania:</b>
                        </p>
                      </Grid>
                      <Grid item xs={9} xl={10}>
                        <p style={{
                          marginLeft: '16px',
                          marginTop: '8px',
                          marginBottom: '8px'
                        }}>{projectForClient.implementationDate || '-'}</p>
                      </Grid>

                      {/*<Grid item xs={3} xl={2}>*/}
                      {/*  <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Wartość faktury:</b>*/}
                      {/*  </p>*/}
                      {/*</Grid>*/}
                      {/*<Grid item xs={9} xl={10}>*/}
                      {/*  <p style={{*/}
                      {/*    marginLeft: '16px',*/}
                      {/*    marginTop: '8px',*/}
                      {/*    marginBottom: '8px'*/}
                      {/*  }}>{projectForClient.invoicesTotal?.toFixed(2) || '-'}</p>*/}
                      {/*</Grid>*/}

                      {/*<Grid item xs={3} xl={2}>*/}
                      {/*  <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Numer faktury:</b></p>*/}
                      {/*</Grid>*/}
                      {/*<Grid item xs={9} xl={10}>*/}
                      {/*  {*/}
                      {/*    projectForClient.invoiceData.length === 0*/}
                      {/*      ? <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>-</p>*/}
                      {/*      : projectForClient.invoiceData.map((invoice, index) => {*/}
                      {/*        return (*/}
                      {/*          <p key={index} style={{*/}
                      {/*            marginLeft: '16px',*/}
                      {/*            marginTop: '8px',*/}
                      {/*            marginBottom: '8px'*/}
                      {/*          }}>{invoice.invoiceNumber || '-'}</p>*/}
                      {/*        )*/}
                      {/*      })*/}
                      {/*  }*/}
                      {/*</Grid>*/}

                      <Grid item xs={3} xl={2}>
                        <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Wyszukaj po
                          lokalizacji:</b></p>
                      </Grid>
                      <Grid item xs={9} xl={10}>
                        <Box display='flex' alignItems='center'>
                          <h5 style={{marginRight: '15px', width: 'fit-content', whiteSpace: 'no-wrap'}}>
                          </h5>
                          <OutlinedInput
                            style={{height: '40px', width: '100%'}}
                            type='text'
                            value={locationSearchValue}
                            onChange={(e) => {
                              if (currentPage !== 1) {
                                setCurrentPage(1)
                              }
                              if (toDoTasksPage !== 1) {
                                setToDoTasksPage(1)
                              }
                              if (doneTasksPage !== 1) {
                                setDoneTasksPage(1)
                              }
                              setLocationSearchValue(e.target.value)
                            }}
                            onKeyPress={e => {
                              if (e.key === 'Enter') {
                                filterTasks()
                                //   dispatch(getStartedProjects({ number: e.target.value, contractor: contractorSearchValue, page: started.page }))
                                //   dispatch(getDoneOrInvoicedProjects({ number: e.target.value, contractor: contractorSearchValue, page: doneOrInvoiced.page }))
                              }
                            }}
                          />
                          <IconButton
                            variant='contained'
                            color='primary'
                            onClick={() => {
                              filterTasks()
                              // dispatch(getStartedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: started.page }))
                              // dispatch(getDoneOrInvoicedProjects({ number: projectSearchValue, contractor: contractorSearchValue, page: doneOrInvoiced.page }))
                            }}
                            style={{marginLeft: '10px'}}
                          >
                            <Search/>
                          </IconButton>
                        </Box>
                      </Grid>

                    </Grid>
                  </Grid>


                  <Grid item xs={12} lg={6}>
                    <Grid container>
                      <Grid item xs={3} xl={2}>
                        <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Kontrahent:</b></p>
                      </Grid>
                      <Grid item xs={9} xl={10}>
                        <p style={{
                          marginLeft: '16px',
                          marginTop: '8px',
                          marginBottom: '8px'
                        }}>{projectForClient.contractor || '-'}</p>
                      </Grid>

                      <Grid item xs={3} xl={2}>
                        <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Kontakt:</b></p>
                      </Grid>
                      <Grid item xs={9} xl={10}>
                        <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                          {
                            projectForClient.contact ? <span>
                          {projectForClient.contact.name},&nbsp;
                                <a
                                  href={`mailto: ${projectForClient.contact.email}`}>{projectForClient.contact.email}</a>&nbsp;
                                <a href={`tel: ${projectForClient.contact.phone}`}>{projectForClient.contact.phone}</a>
                        </span>
                              : '-'
                          }
                        </p>
                      </Grid>

                      <Grid item xs={3} xl={2}>
                        <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Nadzór:</b></p>
                      </Grid>
                      <Grid item xs={9} xl={10}>
                        {
                          projectForClient.supervisors.length === 0
                            ? <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>-</p>
                            : projectForClient.supervisors.map((supervisor, index) => {
                              return (
                                <p key={index} style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                            <span>
                              {supervisor.name},&nbsp;
                              <a href={`mailto: ${supervisor.email}`}>{supervisor.email}</a>&nbsp;
                              <a href={`tel: ${supervisor.phone}`}>{supervisor.phone}</a>
                            </span>
                                </p>
                              )
                            })}
                      </Grid>

                      <Grid item xs={3} xl={2}>
                        <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Project Manager:</b>
                        </p>
                      </Grid>
                      <Grid item xs={9} xl={10}>
                        <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                          {
                            projectForClient.projectManager ? <span>
                          {projectForClient.projectManager.name},&nbsp;
                                <a
                                  href={`mailto: ${projectForClient.projectManager.email}`}>{projectForClient.projectManager.email}</a>&nbsp;
                                <a
                                  href={`tel: ${projectForClient.projectManager.phone}`}>{projectForClient.projectManager.phone}</a>
                        </span>
                              : '-'
                          }
                        </p>
                      </Grid>

                      <Grid item xs={3} xl={2}>
                        <p style={{textAlign: 'right', marginTop: '8px', marginBottom: '8px'}}><b>Account Manager:</b>
                        </p>
                      </Grid>
                      <Grid item xs={9} xl={10}>
                        <p style={{marginLeft: '16px', marginTop: '8px', marginBottom: '8px'}}>
                          {
                            projectForClient.accountManager ? <span>
                          {projectForClient.accountManager.name},&nbsp;
                                <a
                                  href={`mailto: ${projectForClient.accountManager.email}`}>{projectForClient.accountManager.email}</a>&nbsp;
                                <a
                                  href={`tel: ${projectForClient.accountManager.phone}`}>{projectForClient.accountManager.phone}</a>
                        </span>
                              : '-'
                          }
                        </p>
                      </Grid>

                    </Grid>
                  </Grid>


                </Grid>
              </Box>
              <Divider/>
              {value === "2" && <Box>
                <h3>Postęp projektu:</h3>
                <Box height='100%' display='flex' alignItems='center'>
                  <div style={{height: '40px', width: '100%', position: 'relative'}}>
                    <p
                      style={{
                        margin: 0,
                        position: 'absolute',
                        color: '#fff',
                        fontWeight: 900,
                        zIndex: 1,
                        height: '20px',
                        fontSize: '12px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {projectForClient.ready}/{projectForClient.total} {projectForClient.total === 0 ? 0 : (projectForClient.ready / projectForClient.total * 100).toFixed(0)}%
                    </p>
                    <LinearProgress style={{height: '20px', width: '100%', borderRadius: '8px'}} variant='determinate'
                                    value={projectForClient.total === 0 ? 0 : (projectForClient.ready / projectForClient.total * 100).toFixed(0)}/>
                  </div>
                </Box>
              </Box>}
              {/*{value === '1' && <Box mt={5}>*/}
              {/*  <Grid container>*/}
              {/*    {tasks.map(task => <Grid key={task.id} item xs={12}><TaskTile projectId={projectId} task={task}/></Grid>)}*/}
              {/*  </Grid>*/}
              {/*</Box>}*/}
            </Box>

            {value === '1' && <Box>
              <Box mt={5}>
                <h3>Zadania niezrealizowane:</h3>
                <Divider/>
                {toDoTasks.length === 0 ? <p>Brak zadań niezrealizowanych</p> : <Box>
                  <Grid container>
                    {toDoTasks.map(task => <Grid key={task.id} item xs={12}><TaskTile projectId={projectId}
                                                                                      task={task}/></Grid>)}
                  </Grid>
                  <Box mt={2} display='flex' justifyContent='flex-end'>
                    <Pagination shape='rounded' page={toDoTasksPage} onChange={(event, page) => setToDoTasksPage(page)}
                                count={toDoTasksLastPage}/>
                  </Box>
                </Box>
                }
              </Box>
              <Box mt={5}>
                <h3>Zadania zrealizowane:</h3>
                <Divider/>
                {doneTasks.length === 0 ? <p>Brak zadań zrealizowanych</p> : <Box>
                  <Grid container>
                    {doneTasks.map(task => <Grid key={task.id} item xs={12}><TaskTile projectId={projectId}
                                                                                      task={task}/></Grid>)}
                  </Grid>
                  <Box mt={2} display='flex' justifyContent='flex-end'>
                    <Pagination shape='rounded' page={doneTasksPage} onChange={(event, page) => setDoneTasksPage(page)}
                                count={doneTasksLastPage}/>
                  </Box>
                </Box>
                }
              </Box>
            </Box>}

            {/*<TabPanel value="1" style={{padding: 0}}>*/}
            {/*  <Grid container style={{borderBottom: '1px solid rgba(0,0,0,0.12)', borderTop: '1px solid rgba(0,0,0,0.12)'}}>*/}
            {/*  <Grid item xs={6}>*/}
            {/*    <Box boxSizing='border-box' p={3} mb={5}>*/}
            {/*      <h3 style={{marginTop: 0}}>Dokumentacja przedmontażowa:</h3>*/}
            {/*      {preAssemblyDocs.length === 0*/}
            {/*          ? <p>Brak dokumentów</p>*/}
            {/*          : <Grid container spacing={2} alignItems='center'>*/}
            {/*            {preAssemblyDocs.map((doc, index) => {*/}
            {/*              return <TaskDocument*/}
            {/*                key={doc.id}*/}
            {/*                doc={doc}*/}
            {/*                setImagePreview={setImagePreview}*/}
            {/*                setShowImagePreview={setShowImagePreview}*/}
            {/*              />*/}
            {/*            })}*/}
            {/*          </Grid>}*/}
            {/*    </Box>*/}
            {/*  </Grid>*/}
            {/*  <Grid item xs={6} style={{borderLeft: '1px solid rgba(0,0,0,0.12)', background: '#d7d7d7'}}>*/}
            {/*    <Box boxSizing='border-box' p={3} mb={5}>*/}
            {/*      <h3 style={{marginTop: 0}}>Dokumentacja pomontażowa:</h3>*/}
            {/*      {postAssemblyDocs.length === 0*/}
            {/*          ? <p>Brak dokumentów</p>*/}
            {/*          : <Grid container spacing={2} alignItems='center'>*/}
            {/*            {postAssemblyDocs.map((doc, index) => {*/}
            {/*              return <TaskDocument*/}
            {/*                key={doc.id}*/}
            {/*                doc={doc}*/}
            {/*                setImagePreview={setImagePreview}*/}
            {/*                setShowImagePreview={setShowImagePreview}*/}
            {/*              />*/}
            {/*            })}*/}
            {/*          </Grid>*/}
            {/*      }*/}
            {/*    </Box>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
            {/*</TabPanel>*/}
            <TabPanel value="2" style={{padding: 0}}>
              <PLMSection currentPage={currentPage} setCurrentPage={setCurrentPage} doneTasks={tasks}
                          projectId={projectId}/>
            </TabPanel>
          </TabContext>
          <Dialog maxWidth='xl' open={showImagePreview} onClose={() => {
            setShowImagePreview(false)
          }}>
            <Box display='flex' justifyContent='flex-end'>
              <IconButton size='large' onClick={() => {
                setShowImagePreview(false)
              }}>
                <Close/>
              </IconButton>
            </Box>
            <DialogContent>
              {imagePreview &&
                <div style={{width: '100%', height: '80vh', position: 'relative', display: 'flex'}}>
                  <Box style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <IconButton
                      size='large'
                      disabled={previewLoading}
                      onKeyDown={e => {
                        if (e.keyCode === 37) {
                          handlePrevPreview()
                        } else if (e.keyCode === 39) {
                          handleNextPreview()
                        }
                      }}
                      onClick={() => handlePrevPreview()}
                    >
                      <ArrowBackIos/>
                    </IconButton>
                  </Box>
                  {
                    imagePreview.docType.includes('image')
                      ? <a onClick={e => e.preventDefault()} href={imagePreview?.url}
                           download={imagePreview?.fileName || imagePreview?.title}>
                        <img style={{height: '100%'}} alt='preview' src={imagePreview?.url}/>
                      </a>
                      : imagePreview.docType.includes('pdf')
                        ? <Grid item xs={12}>
                          <Box height='100%' display='flex' alignItems='center' flexDirection='column'
                               justifyContent='center'>
                            <Box display='flex' justifyContent='center'>
                              <IconButton color='primary' onClick={() => {
                                var a = document.createElement("a"); //Create <a>
                                a.href = imagePreview.url; //Image Base64 Goes here
                                a.download = imagePreview.fileName; //File name Here
                                a.click(); //Downloaded file
                              }}>
                                <PictureAsPdf style={{fontSize: 80}}/>
                              </IconButton>
                            </Box>
                            <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all', cursor: 'pointer'}}
                               onClick={() => {
                                 var a = document.createElement("a"); //Create <a>
                                 a.href = imagePreview.url; //Image Base64 Goes here
                                 a.download = imagePreview.fileName; //File name Here
                                 a.click(); //Downloaded file
                               }}>
                              {imagePreview.fileName}
                            </p>
                          </Box>
                        </Grid>
                        : <Grid item xs={12}>
                          <Box height='100%' display='flex' alignItems='center' flexDirection='column'
                               justifyContent='center'>
                            <Box display='flex' justifyContent='center'>
                              <IconButton color='primary' onClick={() => {
                                var a = document.createElement("a"); //Create <a>
                                a.href = imagePreview.url; //Image Base64 Goes here
                                a.download = imagePreview.fileName; //File name Here
                                a.click(); //Downloaded file
                              }}>
                                <InsertDriveFile style={{fontSize: 80}}/>
                              </IconButton>
                            </Box>
                            <p style={{textAlign: 'center', marginTop: '5px', wordBreak: 'break-all', cursor: 'pointer'}}
                               onClick={() => {
                                 var a = document.createElement("a"); //Create <a>
                                 a.href = imagePreview.url; //Image Base64 Goes here
                                 a.download = imagePreview.fileName; //File name Here
                                 a.click(); //Downloaded file
                               }}>
                              {imagePreview.fileName}
                            </p>
                          </Box>
                        </Grid>

                  }
                  <Box style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  >
                    <IconButton
                      size='large'
                      disabled={previewLoading}
                      onKeyDown={e => {
                        if (e.keyCode === 37) {
                          handlePrevPreview()
                        } else if (e.keyCode === 39) {
                          handleNextPreview()
                        }
                      }
                      }
                      onClick={() => handleNextPreview()}
                    >
                      <ArrowForwardIos/>
                    </IconButton>
                  </Box>
                </div>
              }
            </DialogContent>
          </Dialog>
          <Dialog maxWidth='xl' open={downloading}>
            <DialogContent>
              <h3>Trwa pobieranie dokumentacji...</h3>
              <Box display='flex' justifyContent='center' m={4}>
                <CircularProgress/>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      }} />
      <Route exact path={`/project/${projectId}/:taskId`} render={() => <TaskDetails
        tasks={[...doneTasks, ...toDoTasks]}
        projectForClient={projectForClient}
        doneTasksFetched={doneTasksFetched}
        toDoTasksFetched={toDoTasksFetched}
        projectId={projectId} />}
      />
    </Switch>
    </>
  )
}

export default ClientProjectDetails
